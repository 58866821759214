@use '@angular/material' as mat;
@use 'variables';

$wt-typography: mat.define-typography-config(
        $font-family: 'Roboto, sans-serif'
);

// default theme https://convertingcolors.com
$wt-primary-colors: (
        100: rgb(255, 238, 104),
        500: variables.$wt-yellow,
        700: rgb(224, 155, 0),
        contrast: (
                100: rgba(black, 0.87),
                500: rgba(white, 0.87),
                700: rgba(white, 0.87)
        )
);

$wt-accent-colors: (
        100: rgb(104, 196, 73),
        500: variables.$wt-green,
        700: rgb(0, 115, 0),
        contrast: (
                100: rgba(black, 0.87),
                500: rgba(black, 0.87),
                700: rgba(white, 0.87),
        )
);

$wt-warn-palette: mat.define-palette(mat.$red-palette);

$wt-primary-palette: mat.define-palette($wt-primary-colors);
$wt-accent-colors: mat.define-palette($wt-accent-colors);

/* stylelint-disable */
$wt-theme: mat.define-light-theme((
        color: (
                primary: $wt-primary-palette,
                accent: $wt-accent-colors,
                warn: $wt-warn-palette,
        ),
        typography: $wt-typography,
        density: 0,
));
/* stylelint-enable */
