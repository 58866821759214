@use '@angular/material' as mat;
@use 'media-mixin';
@use 'variables';

@mixin page-title {
    font-size: 26px;
    line-height: 34px;
    font-weight: 400;
    color: variables.$default-text-color;

    @include media-mixin.media(xsmall) {
        line-height: 28px;
    }
}

@mixin title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    color: variables.$default-text-color;

    @include media-mixin.media(xsmall) {
        line-height: 26px;
    }
}

@mixin small-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    color: variables.$default-text-color;

    @include media-mixin.media(xsmall) {
        line-height: 22px;
    }
}

@mixin caption {
    @include grey-text;

    font-weight: 400;
    font-size: 13px;
}

@mixin error-message {
    @include red-text;

    font-weight: 400;
    font-size: 13px;
}

@mixin body {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: variables.$default-text-color;
}

@mixin body-bold {
    @include body;
    font-weight: 600;
    color: variables.$default-text-color;
}

@mixin yellow-text {
    color: variables.$wt-yellow
}

@mixin green-text {
    color: variables.$wt-green
}

@mixin red-text {
    color: mat.get-color-from-palette(mat.$red-palette, 600);
}

@mixin grey-text {
    color: variables.$grey;
}
