@use 'variables';
@use 'typography-mixins';
@use 'media-mixin';

html,
body {
    height: 100%;
    min-width: 320px;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: variables.$default-text-color;
    @include typography-mixins.body;
    background-color: variables.$default-background-color;
}

// generally remove underlines from links
a {
    color: variables.$wt-yellow;
    text-decoration: none;

    &:visited {
        color: variables.$wt-yellow;
    }

    &.default-text-link {
        color: variables.$default-text-color;

        &:visited {
            color: variables.$default-text-color;
        }
    }
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

.clickable {
    cursor: pointer;

    &:focus {
        outline: 0;
    }

    &.disabled {
        cursor: default;
    }
}

@mixin non-interactive {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
}

.non-interactive {
    @include non-interactive
}

button[type="submit"].hidden {
    width: 0;
    height: 0;
    position: absolute;
    top: -99999px;
    left: -99999px;
}

.prevent-wrap {
    white-space: nowrap;
}

.break-word {
    word-break: break-word;
}

// Typography
.wt-page-title {
    @include typography-mixins.page-title;
}

.wt-title {
    @include typography-mixins.title;
}

.wt-small-title {
    @include typography-mixins.small-title;
}

.wt-caption {
    @include typography-mixins.caption;
}

.wt-error-message {
    @include typography-mixins.error-message;
}

.wt-body {
    @include typography-mixins.body;
}

.wt-body-bold {
    @include typography-mixins.body-bold;
}

.wt-yellow-text {
    @include typography-mixins.yellow-text;
}

.wt-green-text {
    @include typography-mixins.green-text;
}

.wt-red-text {
    @include typography-mixins.red-text;
}

.wt-grey-text {
    @include typography-mixins.grey-text;
}

a.navigation-link {
    color: variables.$default-text-color;

    &:visited {
        color: variables.$default-text-color;
    }

    &.active {
        color: variables.$wt-yellow;
    }
}

.text-align-left {
    text-align: left !important;
}

.text-align-center {
    text-align: center !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.section {
    display: flex;
    justify-content: space-around;

    &.background {
        background-color: variables.$grey-background-color;
    }
}

.container {
    width: calc(100% - 2 * #{variables.$spacing-default});
    max-width: 1200px;
    margin: 0 variables.$spacing-default;
    padding: variables.$spacing-large 0;
    color: variables.$default-text-color;
    text-align: center;

    @include media-mixin.media(small) {
        padding: variables.$spacing-big 0;
    }

    &.narrow {
        max-width: 800px;
    }

    &.page-title {
        padding-top: variables.$spacing-small;
        padding-bottom: variables.$spacing-small;

        @include media-mixin.media(xsmall) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .section-title {
        margin-bottom: variables.$spacing-big;

        h2 {
            @include typography-mixins.page-title;
            margin-bottom: variables.$spacing-small;
        }

        &::after {
            width: 200px;
            height: 1px;
            display: block;
            background-color: variables.$wt-yellow;
            content: "";
            margin: variables.$spacing-default auto 0;
        }
    }

    .sub-section-title {
        padding-top: variables.$spacing-big;
        margin-bottom: variables.$spacing-default;

        h3 {
            @include typography-mixins.title;
            line-height: 0;
        }
    }
}
