@mixin media($size) {
    $xs: 599px;
    $sm: 959px;
    $md: 1279px;

    @if $size == 'xsmall' {
        @media (max-width: $xs) {
            @content;
        }
    } @else if $size == 'small' {
        @media (max-width: $sm) {
            @content;
        }
    } @else if $size == 'medium' {
        @media (max-width: $md) {
            @content;
        }
    } @else {
        @warn "Supported medias: xsmall, small, medium";
    }
}
