$wt-yellow: rgb(255, 182, 46);
$wt-green: rgb(43, 141, 13);

$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$light-grey: #eeeeef;
$grey: rgba(0, 0, 0, 0.54);
$dark-grey: #2b2b2b;

$default-text-color: rgb(95, 95, 95);
$default-background-color: $white;
$grey-background-color: rgb(245, 245, 245);

$spacing-small: 8px;
$spacing-default: 16px;
$spacing-big: 25px;
$spacing-large: 50px;

$header-height: 85px;
$small-header-height: 50px;
$logo-height: 65px;
$small-logo-height: 35px;
